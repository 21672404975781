import { Row, Col, Alert} from 'antd'
import { AudioRecorder } from '../component/AudioRecorder'

export const Home = () => {

    return (
        <Row style={{paddingTop: 20}}>
            <Col span={12} offset={6}>
                <Alert message="Новости в разработке..." type="warning" />
                <AudioRecorder duration={5000} />
            </Col>
        </Row>
    )
}