import { AudioFilled } from '@ant-design/icons'
import { Button, Flex, Progress, Space } from 'antd'
import Meyda from 'meyda'
import { useRef, useState } from 'react'
import api from '../context/auth/AuthState'

export const AudioRecorder = ({duration}) => {

    const [counter, setCounter] = useState(duration)
    const [recording, setRecording] = useState(false)
    const [recordedUrl, setRecordedUrl] = useState('')
    const mediaStream = useRef(null)
    const mediaRecorder = useRef(null)
    const audio = useRef([])
    const tick = 200

    const startRecording = () => {
        console.log("startRecording")
        setRecordedUrl('')
        navigator.getUserMedia = navigator.getUserMedia || navigator.mediaDevices.getUserMedia
        navigator.getUserMedia(
            { audio: true },
            stream => {
                console.log('Permission Granted')
                mediaStream.current = stream
                mediaRecorder.current = new MediaRecorder(stream)
                mediaRecorder.current.ondataavailable = onDataAvailableHandler
                mediaRecorder.current.onstop = onStopHandler
                mediaRecorder.current.start()
                setRecording(true)

                for(let time = tick; time < duration; time += tick) {
                    setTimeout(() => {
                        setCounter(duration - time)
                    }, time)
                }
                setTimeout(() => {
                    setCounter(0)
                    setRecording(false)
                    stopRecording()
                }, duration)
            },
            error => {
                console.error('Error accessing microphone:', error)
            })

    }

    const onDataAvailableHandler = blobEvent => {
        console.log('blobEvent', blobEvent)
        if(blobEvent.data.size > 1) {
            audio.current = blobEvent.data
        }
    }

    const onStopHandler = () => {
        console.log('onstop...')
        const recordedBlob = audio.current
        console.log('recordedBlob', recordedBlob)
        const url = URL.createObjectURL(recordedBlob)
        setRecordedUrl(url)
        audio.current = null
        sendMfcc(recordedBlob)
    }

    const sendMfcc = async(blob) => {
        console.log('sendMfcc', blob)
        const arrayBuffer = await blob.arrayBuffer()
        console.log('arrayBuffer', arrayBuffer)
        const audioContext = new AudioContext()
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)
        const mfccArray = getMfcc(audioBuffer)
        console.log('mfccArray', mfccArray)
        const float32Array = new Float32Array(mfccArray)
        console.log('float32Array', float32Array)
        console.log('buffer', float32Array.buffer)
        const mfccBlob = new Blob([float32Array.buffer])
        console.log('mfccBlob', mfccBlob)
        let formData = new FormData()
        formData.append("audio", mfccBlob)
        const response = api.post('/speech/uploadAudio', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        response.then(resp => {
            console.log('resp', resp.data)
        })

    }

    const getMfcc = audioBuffer => {
        console.log('getMfcc')
        const arrayBuffer = audioBuffer.getChannelData(0).buffer
        const bufferLength = arrayBuffer.byteLength
        console.log('bufferLength', bufferLength)
        const frameSize = 1024
        const numFrames = Math.floor(bufferLength / frameSize)
        console.log('numFrames', numFrames)
        const resultArr = []
        for(let i = 0; i < numFrames; i++) {
            const start = i * frameSize
            const end = start + frameSize
            const frame = arrayBuffer.slice(start, end)
            const signal = new Float32Array(frame)
            const coefs = Meyda.extract('mfcc', signal)
            coefs.forEach(coef => {
                resultArr.push(coef)
            })
        }
        return resultArr
    }

    const stopRecording = () => {
        console.log('stopRecording')
        if(mediaRecorder.current && mediaRecorder.current.state === 'recording') {
            console.log('Stop')
            mediaRecorder.current.stop()
        }
    }

    const renderStartRecordButton = () => {
        if(recording) {
            return null
        }
        return (
            <Button
                icon={<AudioFilled />}
                type="primary"
                shape="circle"
                onClick={startRecording}
                ghost
            />
        )
    }

    const renderRecording = () => {
        if(!recording) {
            return null
        }
        return (
            <Progress
                type="circle"
                percent={100 * counter / duration}
                format={() => ""}
                size={30}
            />
        )
    }

    const play = () => {
        const audio = new Audio(recordedUrl)
        audio.play()
    }

    const renderAudioPlayer = () => {
        if(!recordedUrl) {
            return null
        }
        return (
            <Button
                onClick={play}
                type="text"
            >
                Прослушать
            </Button>
        )
    }


    return (
        <div>
            <Space>
                <Flex
                    align="center"
                    justify="center"
                    // style={{
                    //     width: 35,
                    //     height: 35
                    // }}
                >
                    { renderStartRecordButton() }
                    { renderRecording() }
                </Flex>
                
                { renderAudioPlayer() }
            </Space>
        </div>
    )
}